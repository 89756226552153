.InfoCardsContainer {
    position: absolute;
    left: 0;
    right: 0;
    top: 320px;
    padding: 0 25px 0 25px;
    z-index: 1000;
  }
@media only screen and (min-width: 667px) {
    .InfoCardsContainer {
        top: auto;
        bottom: 115px;
        width: 50%;
        padding: 10px 0 0 25px;
    }
}
@media only screen and (min-width: 960px) {
    .InfoCardsContainer {
        bottom: 95px;
    }
}
